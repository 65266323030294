import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Select, useToast } from '@chakra-ui/react'
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { FIREBASE_NOTIFICATION_PAGE } from '../config';
import { getUserRole } from '../services/dataApi/dataProvider';
import { headerProvider, showErrorMessage } from '../common/CommonFunctions';
import DatePicker from "react-datepicker";



const Navbar = (props) => {
  const { sortByMethod, showModalFun, addNewActivity, setOnUpdate, littleActsDateFilter, filterbyMethod } = props
  let location = useLocation();
  const toast = useToast();
  const Navigate = useNavigate()
  const [sortByFieldConvo, setSortByFieldConvo] = useState('localDateTime')
  const [sortByFieldCarConvo, setSortByFieldCarConvo] = useState('all')
  const [sortByFieldLetters, setSortByFieldLetters] = useState('createdOn')
  const [orderByField, setOrderByField] = useState('DESC')
  const [orderByType, setOrderByType] = useState('DESC')
  const [selectedDate, setSelectedDate] = useState();
  const [notificationBtn, setNotificationBtn] = useState(false);
  const getUserRoleData = useCallback(async () => {
    try {
      const headers = headerProvider();
      const response = await getUserRole(headers);
      if (response) {
        if (response?.response?.role === 'SupportAdmin') {
          setNotificationBtn(true);
        }
      } else {
        showErrorMessage('Server Error', 'No Response from server', 'error', toast)
      }
    }
    catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => { getUserRoleData() }
    , [getUserRoleData]);
  let locationName = location.pathname.split('/')[1]
  const sort = {
    field: locationName === 'conversations' ? sortByFieldConvo : sortByFieldLetters,
    order: orderByField
  }
  const handleClickASC = () => {
    if (orderByType === 'ASC') {
      setOrderByType('DESC')
    }
    else {
      setOrderByType('ASC')
    }
    sort.order = 'ASC'
    sort.field = locationName === 'conversations' ? sortByFieldConvo : sortByFieldLetters
    sortByMethod(sort)
  }
  const handleClickDESC = () => {
    if (orderByType === 'ASC') {
      setOrderByType('DESC')
    }
    else {
      setOrderByType('ASC')
    }
    sort.order = 'DESC'
    sort.field = locationName === 'conversations' ? sortByFieldConvo : sortByFieldLetters
    sortByMethod(sort)
  }
  const handleChange = (e) => {
    if (locationName === 'studyUsers') {
      setSortByFieldCarConvo(e.target.value)
    }
    else if (locationName === 'conversations') {
      setSortByFieldConvo(e.target.value)
    }
    else {
      setSortByFieldLetters(e.target.value)
    }
    sort.field = e.target.value
    sort.order = orderByType
    sortByMethod(sort)
  }

  const handleNotification = () => {
    window.location.href = FIREBASE_NOTIFICATION_PAGE;

  }

  const handleAddActivity = () => {
    addNewActivity();
    setOnUpdate(false);
  };

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `${longMonth} ${fullYear}`;

    return (
      <span
        title={tooltipText}
        className="flex items-center justify-center p-3 py-2.5 px-5 mb-2 text-sm font-semibold text-gray-700 bg-white/50 rounded-md shadow-md hover:bg-white/50 transition duration-200 ease-in-out dark:text-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
      >
        {shortMonth}
      </span>
    );
  };

  const handleOnChangeDatePicker = (date) => {
    setSelectedDate(date);
    localStorage.setItem("dateFilter", date);
    littleActsDateFilter();
  };

  return (
    <nav className='flex-no-wrap h-32 relative flex w-full items-center justify-between bg-[#f4f7fe] py-2 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start lg:py-4'>
      <div className='flex justify-between h-20 w-full'>
        <div className='flex items-center p-3'>
          <p className='text-2xl font-bold'>{
            locationName === 'studyUsers'
              ? 'Study Users'
              :
              locationName.toUpperCase()}</p>
        </div>
        {
          locationName === 'letters' ?
            <div className='flex items-center p-3'>
              <div className='flex items-center p-1'>
                <button onClick={() => Navigate('/createLetter')} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                  Create a Letter
                </button>
              </div>
              <div className='flex items-center p-1'>
                {
                  orderByType === 'DESC' ? <button onClick={handleClickDESC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-700 focus:outline-none bg-gray-100 rounded-lg border border-gray-200 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <FaArrowDown size={20} />
                  </button>
                    : <button onClick={handleClickDESC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                      <FaArrowDown size={20} />
                    </button>
                }
              </div>
              <div className='flex items-center p-1'>
                {
                  orderByType === 'ASC' ? <button onClick={handleClickASC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-700 focus:outline-none bg-gray-100 rounded-lg border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <FaArrowUp size={20} />
                  </button>
                    : <button onClick={handleClickASC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                      <FaArrowUp size={20} />
                    </button>
                }
              </div>
              <div className='flex items-center p-3 py-2.5 px-5 me-2 mb-2'>
                <Select placeholder='Sort by' onChange={handleChange} bg='white'>
                  <option value='type'>Type</option>
                </Select>
              </div>
            </div>
            :
            locationName === 'conversations' ?
              <div className='flex'>
                <div className='flex items-center p-1'>
                  {
                    orderByType === 'ASC' ? <button onClick={handleClickASC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-700 focus:outline-none bg-gray-100 rounded-lg border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                      <FaArrowUp size={20} />
                    </button>
                      : <button onClick={handleClickASC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        <FaArrowUp size={20} />
                      </button>
                  }
                </div>
                <div className='flex items-center p-1'>
                  {
                    orderByType === 'DESC' ? <button onClick={handleClickDESC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-700 focus:outline-none bg-gray-100 rounded-lg border border-gray-200 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                      <FaArrowDown size={20} />
                    </button>
                      : <button onClick={handleClickDESC} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        <FaArrowDown size={20} />
                      </button>
                  }
                </div>
                <div className='flex items-center p-3 py-2.5 px-5 me-2 mb-2'>
                  <Select placeholder='Sort by' onChange={handleChange} bg='white'>
                    <option value='type'>Type</option>
                    <option value='isActive'>Is Active</option>
                    <option value='expiringOn'>Expiring On</option>
                    <option value='localDateTime'>Updated Date</option>
                  </Select>
                </div>
              </div>
              : locationName === "little-acts" ? (
                <div className="flex">
                  <div className="flex items-center p-3 py-2.5 px-5 me-2 mb-2">
                    <text className="flex items-center  me-2 text-sm font-medium text-gray-900  hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700">
                      Created For
                    </text>
                    <div
                      className="flex items-center text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-1 focus:ring-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
                    >
                      <DatePicker
                        selected={selectedDate}
                        renderMonthContent={renderMonthContent}
                        showMonthYearPicker
                        dateFormat="MMM / yyyy"
                        onChange={(date) => {
                          handleOnChangeDatePicker(date);
                        }}
                        placeholderText='--select month and Year--'
                        className="flex items-center p-3 py-3 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg   hover:bg-gray-100 hover:text-blue-700 focus:outline-none  dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                      />
                      <span
                        className="flex items-center  text-2xl  text-gray-200 bg-white  dark:text-gray-300 "
                      >|
                      </span>
                      <button onClick={() => {
                        localStorage.setItem("dateFilter", '');
                        littleActsDateFilter();
                      }}
                        className="flex items-center p-3 py-3 px-3  text-sm font-medium text-gray-900 bg-white rounded-lg  hover:bg-gray-100 hover:text-blue-700 focus:outline-none  dark:bg-gray-800  dark:text-gray-300 dark:hover:bg-gray-700"
                      >All
                      </button>
                    </div>
                  </div>
                  <div className="p-3">
                    <button
                      onClick={handleAddActivity}
                      className="flex items-center p-3 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
                    >
                      New Activity
                    </button>
                  </div>
                </div>
              ) :
                locationName === 'studyUsers' ?
                  <div className='flex items-center p-1'>
                    <div className='flex items-center p-3 py-2.5 px-5 mb-2'>
                      <Select placeholder='' onChange={handleChange} bg='white'>
                        <option value='all'>All Users</option>
                        <option value='trail'>Trail Users</option>
                        <option value='control'>Control users</option>
                      </Select>
                    </div>
                    {/* <div className='flex items-center p-3 py-2.5 px-5 me-2 mb-2'>
                    <Select placeholder='Sort by' onChange={handleChange} bg='white'>
                      <option value='type'>Type</option>
                      <option value='isActive'>Is Active</option>
                      <option value='expiringOn'>Expiring On</option>
                      <option value='localDateTime'>Updated Date</option>
                    </Select>
                  </div> */}
                    <button onClick={() => showModalFun()} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                      Add User
                    </button>
                  </div>
                  :
                  locationName === 'dashboard' ? <div className='flex items-center p-1'>
                    {notificationBtn && <button onClick={handleNotification} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                      Send Notification
                    </button>}
                  </div> :
                    <div>

                    </div>
        }
      </div>
    </nav>
  )
}

export default Navbar